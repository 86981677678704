// import Bootstrap overrides form custom.scss
@import "custom";

// design aide
/** {
    border: 1px solid red;
}
*/

/**
* General
*/

// add margin to prevent content
// from going under top navigation
.mt-72 {
    margin-top: 72px;
}

.mt-100 {
    margin-top: 100px;
}

// add margin to prevent content
// from going under admin navigation
.mt-50 {
    margin-top: 50px;
}

// additional backgrounds
.bg-black {
    background-color: #000;
}

.img-transition {
    /*overflow: hidden;*/
    transition: transform .9s cubic-bezier(.215,.61,.355,1);
}

// container for hidden content
// to be shown on hover

div.show-on-hover-content > div.bg-scrim {
    margin: 0 0 87px;
}

.show-on-hover-container > div.show-on-hover-content {
    display: none;
}

.show-on-hover-container:hover > div.show-on-hover-content {
	display: block;
}

.initial-letter {
    float: left;
    font-size: 5.1em;
    line-height: 60px;
    padding-top: 5px;
    padding-right: 8px;
}

/**
* Keyframe Animations
*/

// Medium devices (tablets, 768px and up)
@media (min-width: 758px) { 
    // expand text width and move image to the right
    @keyframes expandTextRight {
        from {
            margin-left: 0%;
            width: 100%;
        }
    
        to {
            margin-left: 0%;
            width: 150%;
        }
    }

    @keyframes shrinkTextRight {
        from {
            margin-left: 0%;
            width: 150%;
        }
    
        to {
            margin-left: 0%;
            width: 100%;
        }
    }

    .expanding-text-right {
        animation: expandTextRight .5s forwards;
    }

    .shrinking-text-right {
        animation: shrinkTextRight .5s forwards;
    }

    // expand text width and move image to the left
    @keyframes expandTextLeft {
        from {
            margin-left: 0%;
            width: 100%;
        }
    
        to {
            margin-left: -50%;
            width: 150%;
        }
    }

    @keyframes shrinkTextLeft {
        from {
            margin-left: -50%;
            width: 150%;
        }
    
        to {
            margin-left: 0%;
            width: 100%;
        }
    }

    .expanding-text-left {
        animation: expandTextLeft .5s forwards;
    }

    .shrinking-text-left {
        animation: shrinkTextLeft .5s forwards;
    }

    // expand hidden text
    @keyframes expandTextDown {
        from {
            max-height: 0;
        }

        to  {
            max-height: auto;
        }
    }

    .expanding-text-down {
        overflow: hidden;
        animation: expandTextDown 1s forwards;
    }
}


/**
* Footer
*/

.bg-footer {
    background-color: #1b1b1b;
}

/**
* Home Page
*/

.bg-banner {
    background: linear-gradient(90deg, rgba(55, 54, 63, 0.8) 5%, rgba(55, 54, 63, 1) 75%);
    //background:rgb(0, 0, 0);
}

.bg-mission {
    height: 375px;
    background: linear-gradient(rgba(24, 18, 18, 0.5), rgba(0, 0, 0, 0.4)),
      /*no-repeat center/cover url("./media/banner.jpg");*/
      no-repeat center/cover url("https://res.cloudinary.com/pocketcinema/image/upload/v1631994620/IMG_1251_t6wzoq.jpg");
}

.header-mission {
    font-size: 3em;
}

.header-page {
    font-size: 2.5em;
}

.section-padding {
    padding-top: 48px;
    padding-bottom: 0;
}

.large-banner {
    padding: 50px 25px;
}

.large-banner-text {
    font-size: 3em;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    .bg-mission {
        height: 440px;
    }

    .large-banner {
        padding: 50px 50px;
    }
    
    .large-banner-text {
        font-size: 3.5em;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 758px) { 
    .bg-mission {
        background: no-repeat center/cover url("https://res.cloudinary.com/pocketcinema/image/upload/v1631994620/IMG_1251_t6wzoq.jpg");
    }

    .bg-mission-overlay {
        background: #fff;
        transform: skew(20deg);
    }
    
    .bg-mission-overlay-content {
        transform: skew(-20deg);
    }

    .header-mission {
        font-size: 4em;
    }

    .header-page {
        font-size: 3em;
    }

    .section-padding {
        padding-bottom: 48px;
    }

    /*.large-banner {
        padding: 150px 75px;
    }
    */
    
    .large-banner-text {
        font-size: 5em;
    }
}

/**
* Page Template
*/

.bg-header-overlay {
    background: rgba(0, 0, 0, 0.65);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 758px) {
    .bg-header-overlay {
        transform: skew(20deg);
    }
    
    .bg-header-overlay-content {
        transform: skew(-20deg);
    }
}

/**
* Subpage Template
*/

.subpage-header-content {
    padding: 50px 35px 35px;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 758px) { 
    .subpage-header {
        height: 440;
        padding-top: 140px;
        background: no-repeat center/cover url(https://res.cloudinary.com/pocketcinema/image/upload/v1629135980/20190809_200552_wtgdpw.jpg);
    }

    .subpage-header-content {
        margin-bottom: -50px;
        padding: 75px 50px 50px;
    }
}

/**
* Productions Page
*/

// remove padding form clickable card
.card.btn {
    padding: 0;
    text-align: left;
}

.modal-full-screen {
    min-width: 100%;
    margin: 0;
}

a.carousel-control-prev,
a.carousel-control-next {
    width: 8%;
}

/**
* Donate Page
*/


/**
* Admin Dashboard
*/

.admin-menu-container {
    z-index: 900;
}

ul.admin-menu > li > a {
    color: inherit;
}

