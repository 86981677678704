// Override default variables before the import
// https://getbootstrap.com/docs/4.1/getting-started/theming/#css-variables
// https://create-react-app.dev/docs/adding-bootstrap/

// Colors
$primary: #ed1e6f;
$info: #7d1fcb;
$warning: rgb(238, 108, 19);


// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";